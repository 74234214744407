import React from "react";
import { graphql } from "gatsby";
import { PageContextProvider } from "src/containers/PageContext";
import Layout from "src/components/Layout";
import SEOStatic from "src/components/SEOStatic";

import transformStandardPageModuleData from "src/libs/transformStandardPageModuleData";

export default function Standard({ pageContext, data }) {
	const { pageMetadata, pageModules } = transformStandardPageModuleData(data);

	return (
		<PageContextProvider context={pageContext}>
			<Layout className="layout--standard">
				<SEOStatic {...pageMetadata} />
				{pageModules &&
					pageModules.map(({ Module, props }, index) => {
						return <Module {...props} key={index} />;
					})}
			</Layout>
		</PageContextProvider>
	);
}

export const query = graphql`
	query StandardPage($pageUri: String, $language: String) {
		entry: craftStandardPageStandardPageEntry(
			uri: { eq: $pageUri }
			language: { eq: $language }
		) {
			...StandardPageEntry
		}
	}
`;
