import React from "react";
import clsx from "clsx";
import { usePageContext } from "src/containers/PageContext";
import { ImgixFluid } from "src/components/Imgix";
import TileList, { TileItem } from "src/components/TileList";
import Link from "src/components/Link";
import transformCtaBlock from "src/libs/transformCtaBlock";

import "style/modules/layout-image-grid.scss";

export default function LayoutImageGrid({
	className,
	heading,
	intro,
	images,
	cta,
}) {
	const language = usePageContext("language");
	const footerCta = transformCtaBlock(cta, language);

	return (
		<div className={clsx("layout-image-grid", className)}>
			{heading && <h2 className="layout-image-grid__heading">{heading}</h2>}
			{intro && !heading && (
				<h3 className="layout-image-grid__intro">{intro}</h3>
			)}

			<TileList className="layout-image-grid__list">
				{images?.map(({ image: [image] }, i) => (
					<TileItem className="layout-image-grid__item" key={i}>
						<div className="layout-image-grid__item__inner">
							<div className="layout-image-grid__item__img-wrapper">
								<ImgixFluid
									{...image}
									className="layout-image-grid__item__img"
									sizes="(min-width: 2200px) 520px, (min-width: 768px) 34vw, 100vw"
								/>
							</div>
						</div>
					</TileItem>
				))}
			</TileList>
			{footerCta?.url && (
				<div className="layout-image-grid__cta-wrapper">
					<Link
						to={footerCta?.url}
						className="layout-image-grid__cta"
						data-cta-click={footerCta?.label}>
						{footerCta?.label}
					</Link>
				</div>
			)}
		</div>
	);
}
