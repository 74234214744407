import React from "react";
import clsx from "clsx";
import Redactor from "src/components/Redactor";
import { usePageContext } from "src/containers/PageContext";

import "style/modules/text-page-header.scss";

export default function TextPageHeader({ className, heading, subheading }) {
	let pageTitle = usePageContext("pageTitle");
	if (subheading) pageTitle = subheading;

	return (
		<div className={clsx("text-page-header", className)}>
			<div className="text-page-header__inner">
				{pageTitle && (
					<span className="text-page-header__subtitle">{pageTitle}</span>
				)}
				<Redactor
					className="text-page-header__heading"
					tagName="h1"
					html={heading}
				/>
			</div>
		</div>
	);
}
