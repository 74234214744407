import React from "react";
import clsx from "clsx";
import { usePageContext } from "src/containers/PageContext";
import TileList, { TileItem } from "src/components/TileList";
import Redactor from "src/components/Redactor";
import Link from "src/components/Link";
import transformLinkList from "src/libs/transformLinkList";

import "style/modules/layout-text-grid.scss";

export default function LayoutTextGrid({ className, blocks }) {
	const language = usePageContext("language");

	return (
		<TileList className={clsx("layout-text-grid", className)}>
			{blocks?.map((item, index) => {
				const [itemCta] = transformLinkList(item.cta, language);

				return (
					<TileItem className="layout-text-grid__item" key={index}>
						<>
							<Redactor
								className="layout-text-grid__content"
								html={item.content}
							/>
							{itemCta && (
								<Link
									className="layout-text-grid__cta layout-text-grid__cta--black"
									to={itemCta.url}
									data-cta-click={itemCta.label}>
									{itemCta.label}
								</Link>
							)}
						</>
					</TileItem>
				);
			})}
		</TileList>
	);
}
