import React from "react";
import clsx from "clsx";
import { usePageContext } from "src/containers/PageContext";
import { ImgixFluid } from "src/components/Imgix";
import TileList, { TileItem } from "src/components/TileList";
import Link from "src/components/Link";
import transformCtaBlock from "src/libs/transformCtaBlock";
import transformLinkList from "src/libs/transformLinkList";

import "style/modules/layout-card-grid.scss";

export default function LayoutCardGrid({
	className,
	heading,
	intro,
	cards,
	cta,
}) {
	const language = usePageContext("language");
	const footerCta = transformCtaBlock(cta, language);

	return (
		<div className={clsx("layout-card-grid", className)}>
			{heading && <h2 className="layout-card-grid__heading">{heading}</h2>}
			{intro && !heading && (
				<h3 className="layout-card-grid__intro">{intro}</h3>
			)}
			<TileList className="layout-card-grid__list">
				{cards?.map((item, i) => {
					const [itemCta] = transformLinkList(item.cta, language);
					const [itemImage] = item.image;

					return (
						<TileItem className="layout-card-grid__item" key={i}>
							<div className="layout-card-grid__item__inner">
								{itemCta?.url ? (
									<Link
										to={itemCta?.url}
										className="layout-card-grid__item__img-wrapper">
										<ImgixFluid
											{...itemImage}
											className="layout-card-grid__item__img layout-card-grid__item__img--hover"
											sizes="(min-width: 2200px) 520px, (min-width: 768px) 34vw, 100vw"
										/>
									</Link>
								) : (
									<div className="layout-card-grid__item__img-wrapper">
										<ImgixFluid
											{...itemImage}
											className="layout-card-grid__item__img"
											sizes="(min-width: 2200px) 520px, (min-width: 768px) 34vw, 100vw"
										/>
									</div>
								)}
								<div className="layout-card-grid__item__content-wrapper">
									{item.title && (
										<h3 className="layout-card-grid__item__title">
											{item.title}
										</h3>
									)}
									{itemCta?.url && (
										<Link
											to={itemCta?.url}
											className="layout-card-grid__item__cta">
											{itemCta?.label}
										</Link>
									)}
								</div>
							</div>
						</TileItem>
					);
				})}
			</TileList>
			{footerCta?.url && (
				<div className="layout-card-grid__cta-wrapper">
					<Link
						to={footerCta?.url}
						className="layout-card-grid__cta"
						data-cta-click={footerCta?.label}>
						{footerCta?.label}
					</Link>
				</div>
			)}
		</div>
	);
}
