export default function transformCtaBlock(cta, language) {
	const {
		ctaEntry: [ctaEntry],
		ctaLabel,
		ctaUrl,
	} = cta;

	return {
		url: ctaEntry ? `/${language}/${ctaEntry.uri}` : ctaUrl,
		label: ctaLabel || ctaEntry?.title,
	};
}
