import React from "react";
import clsx from "clsx";
import Redactor from "src/components/Redactor";
import "style/modules/text-blockquote.scss";

export default function TextBlockquote({
	className,
	content,
	source,
	size = "large",
}) {
	return (
		<div
			className={clsx(
				"text-blockquote",
				`text-blockquote--${size}`,
				className
			)}>
			<div className="text-blockquote__inner">
				<Redactor className="text-blockquote__quote" html={content} />
				{source && <span className="text-blockquote__source">{source}</span>}
			</div>
		</div>
	);
}
