import React from "react";
import clsx from "clsx";
import Redactor from "src/components/Redactor";
import "style/modules/video-embed.scss";

export default function VideoEmbed({ className, embedCode }) {
	return (
		<div className={clsx("video-embed", className)}>
			<Redactor className="video-embed__inner" html={embedCode} />
		</div>
	);
}
