import React, { useEffect, useRef, Fragment } from "react";
import clsx from "clsx";
import Link from "src/components/Link";
import usePinpointJobs from "src/libs/usePinpointJobs";

import "style/modules/layout-workable-grid.scss";

export default function LayoutWorkableGrid({ className }) {
	const jobs = usePinpointJobs();

	return (
		<div className={clsx("layout-workable-grid", className)}>
			<div className="layout-workable-grid__inner">
				{Object.keys(jobs).map((groupTitle, index) => {
					return (
						<Fragment key={index}>
							<h2 className="whr-group">{groupTitle}</h2>
							<div className="whr-items">
								{jobs[groupTitle].map((item, index) => {
									return (
										<Link
											key={`${groupTitle}-${index}`}
											className="whr-item"
											to={item.url}
											target="_blank">
											<h3 className="whr-title">{item.title}</h3>
											<ul className="whr-info">
												<li className="whr-dept">
													<span>Department:</span>
													{item.department}
												</li>
											</ul>
										</Link>
									);
								})}
							</div>
						</Fragment>
					);
				})}
			</div>
		</div>
	);
}
