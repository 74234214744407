import React from "react";
import clsx from "clsx";
import Redactor from "src/components/Redactor";
import "style/modules/text-rich.scss";

export default function TextRich({ className, content }) {
	return (
		<div className={clsx("text-rich", className)}>
			<Redactor className={clsx("text-rich__content")} html={content} />
		</div>
	);
}
