import { graphql } from "gatsby";
import TextPageHeader from "src/modules/TextPageHeader";
import TextRich from "src/modules/TextRich";
import TextBlockquote from "src/modules/TextBlockquote";
import ImageSingle from "src/modules/ImageSingle";
import ImageTrio from "src/modules/ImageTrio";
import VideoEmbed from "src/modules/VideoEmbed";
import LayoutCardGrid from "src/modules/LayoutCardGrid";
import LayoutWorkableGrid from "src/modules/LayoutWorkableGrid";
import LayoutTextGrid from "src/modules/LayoutTextGrid";
import LayoutImageGrid from "src/modules/LayoutImageGrid";
import LayoutZigZagStack from "src/modules/LayoutZigZagStack";

const PAGE_MODULE_MAP = {
	Craft_standardPageModules_textRich_BlockType: TextRich,
	Craft_standardPageModules_textBlockquote_BlockType: TextBlockquote,
	Craft_standardPageModules_imageSingle_BlockType: ImageSingle,
	Craft_standardPageModules_imageTrio_BlockType: ImageTrio,
	Craft_standardPageModules_videoEmbed_BlockType: VideoEmbed,
	Craft_standardPageModules_layoutCardGrid_BlockType: LayoutCardGrid,
	Craft_standardPageModules_layoutWorkableGrid_BlockType: LayoutWorkableGrid,
	Craft_standardPageModules_layoutTextGrid_BlockType: LayoutTextGrid,
	Craft_standardPageModules_layoutImageGrid_BlockType: LayoutImageGrid,
	Craft_standardPageModules_layoutZigZagStack_BlockType: LayoutZigZagStack,
};

export default function transformStandardPageModuleData(data) {
	const {
		entry: {
			title,
			description,
			pageHeader: [pageHeader],
			pageModules,
		},
	} = data;

	return {
		pageMetadata: { title, description },
		pageModules: [{ Module: TextPageHeader, props: pageHeader }].concat(
			pageModules
				.map(({ moduleType, ...moduleData }) => {
					const Module = PAGE_MODULE_MAP[moduleType];
					if (!Module) return null;
					return {
						Module,
						props: transformModuleData(moduleType, moduleData),
					};
				})
				.filter(Boolean)
		),
	};
}

export function transformModuleData(moduleType, moduleData) {
	const data = { __typename: null, ...moduleData };

	switch (moduleType) {
		case "Craft_standardPageModules_layoutImageGrid_BlockType":
		case "Craft_standardPageModules_layoutCardGrid_BlockType":
			const {
				cta: [cta],
			} = data;

			return {
				...data,
				cta,
			};

		default:
			return data;
	}
}

export const fragment = graphql`
	fragment CaseStudyPageEntry on Craft_caseStudyPage_caseStudyPage_Entry {
		title
		description: metaDescription
		pageHeader: standardPageHeader {
			...StandardPageHeader
		}

		pageModules: standardPageModules {
			...StandardPageModules
		}
	}

	fragment StandardPageEntry on Craft_standardPage_standardPage_Entry {
		title
		description: metaDescription
		pageHeader: standardPageHeader {
			...StandardPageHeader
		}

		pageModules: standardPageModules {
			...StandardPageModules
		}
	}

	fragment StandardPageHeader on Craft_standardPageHeader_BlockType {
		subheading
		heading
	}

	fragment StandardPageModules on Craft_standardPageModules_MatrixField {
		moduleType: __typename
		... on Craft_standardPageModules_imageSingle_BlockType {
			image {
				craftSrc: url
				w: width
				h: height
			}
		}

		... on Craft_standardPageModules_textRich_BlockType {
			content: text
		}

		... on Craft_standardPageModules_layoutImageGrid_BlockType {
			heading
			intro
			images {
				... on Craft_images_BlockType {
					image {
						craftSrc: url
						w: width
						h: height
					}
				}
			}
			cta: footerCta {
				... on Craft_footerCta_BlockType {
					ctaLabel
					ctaUrl
					ctaEntry {
						title
						uri
					}
				}
			}
		}

		... on Craft_standardPageModules_textBlockquote_BlockType {
			content: quote
			source
			size
		}

		... on Craft_standardPageModules_layoutCardGrid_BlockType {
			heading
			intro
			cards {
				... on Craft_cards_BlockType {
					image {
						craftSrc: url
						w: width
						h: height
					}

					title: cardTitle

					cta {
						... on Craft_cta_internalLink_BlockType {
							linkEntry {
								uri
								title
							}
							linkLabel
						}

						... on Craft_cta_externalLink_BlockType {
							linkUrl
							linkLabel
						}
					}
				}
			}

			cta: footerCta {
				... on Craft_footerCta_BlockType {
					ctaLabel
					ctaUrl
					ctaEntry {
						title
						uri
					}
				}
			}
		}

		... on Craft_standardPageModules_videoEmbed_BlockType {
			embedCode
		}

		... on Craft_standardPageModules_layoutWorkableGrid_BlockType {
			accountId
		}

		... on Craft_standardPageModules_layoutZigZagStack_BlockType {
			rows {
				... on Craft_rows_BlockType {
					content: text
					image {
						craftSrc: url
						w: width
						h: height
					}
					cta {
						... on Craft_cta_internalLink_BlockType {
							linkEntry {
								uri
								title
							}
							linkLabel
						}
						... on Craft_cta_externalLink_BlockType {
							linkUrl
							linkLabel
						}
					}
				}
			}
			design
		}

		... on Craft_standardPageModules_layoutTextGrid_BlockType {
			blocks {
				... on Craft_blocks_BlockType {
					content: text
					cta {
						... on Craft_cta_internalLink_BlockType {
							linkEntry {
								uri
								title
							}
							linkLabel
						}
						... on Craft_cta_externalLink_BlockType {
							linkUrl
							linkLabel
						}
					}
				}
			}
		}

		... on Craft_standardPageModules_imageTrio_BlockType {
			images {
				... on Craft_images_BlockType {
					image {
						craftSrc: url
						w: width
						h: height
					}
					caption
				}
			}
		}
	}
`;
