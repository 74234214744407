import { useState, useEffect } from "react";

export default function usePinpointJobs(callback = null) {
	const [jobs, setJobs] = useState([]);

	useEffect(() => {
		async function fetchJobs() {
			return fetch("/.netlify/functions/pinpoint")
				.then((response) => response.json())
				.catch((error) => {
					throw error;
				});
		}

		fetchJobs().then(transformJobs).then(setJobs);

		if (callback) callback();
	}, [setJobs, callback]);

	return jobs;
}

function transformJobs(jobs) {
	const { data, included } = jobs;
	const { departments, locations } = included.reduce(
		(groups, item) => {
			const { id, type, attributes } = item;

			if (!groups[type]) return groups;
			groups[type][id] = attributes.name;

			return groups;
		},
		{
			departments: {},
			locations: {},
		}
	);

	return data.reduce((jobs, item) => {
		const { attributes, relationships } = item;
		const location = locations[relationships?.location?.data?.id];
		if (!location) return jobs;

		if (!jobs[location]) jobs[location] = [];
		jobs[location].push({
			title: attributes.title,
			url: attributes.url,
			department: departments[relationships?.department?.data?.id],
		});
		return jobs;
	}, {});
}
