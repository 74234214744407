import React from "react";
import clsx from "clsx";
import { ImgixFluid } from "src/components/Imgix";
import "style/modules/image-single.scss";

export default function ImageSingle({ className, image: [image] }) {
	return (
		<div className={clsx("image-single", className)}>
			<ImgixFluid {...image} className="image-single__img" />
		</div>
	);
}
