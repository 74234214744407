import React from "react";
import clsx from "clsx";
import "style/components/tile-list.scss";

export default function TileList({ className, children }) {
	return (
		<div className={clsx("tile-list", className)}>
			<div className="tile-list__inner">{children}</div>
		</div>
	);
}

export function TileItem({ className, children }) {
	return <div className={clsx("tile-list__item", className)}>{children}</div>;
}
